export default [ {
  path: '/main',
  name: 'mainPage',
  redirect: '/home',
  component: () => import('../views/main/index'),
  children: [{
    path: '/home',
    name: 'Home',
    component: () => import('../views/main/home')
  },
  {
    path: '/leaves/new',
    name: 'requestLeaves',
    component: () => import('../views/main/leaves/requestLeave'),
    meta: {
      requiresHEADAuth: true,
      requiresHRAuth: true,
      requiresTLAuth: true,
      requiresAUDITORAuth: true
    }
  },
  {
    path: '/company/leaves',
    name: 'userLeaves',
    component: () => import('../views/main/leaves/companyLeaves')
  },
  {
    path: '/leaves/manage',
    name: 'manageLeaves',
    component: () => import('../views/main/leaves/manageLeaves'),
    meta: {
      requiresCEOAuth: true,
      requiresHRAuth: true,
      requiresHEADAuth: true,
      // requiresAUDITORAuth: true
    }
  },
  {
    path: '/target',
    name: 'target',
    component: () => import('../views/main/target')
  },
  {
    path: '/employees/add',
    name: 'addEmployee',
    component: () => import('../views/main/employees/addEmployee'),
    meta: {
      requiresCEOAuth: true,
      requiresHRAuth: true
    }
  },
  {
    path: '/employees/salaries',
    name: 'salaries',
    component: () => import('../views/main/employees/salaries'),
    meta: {
      requiresCEOAuth: true,
      requiresHRAuth: true
    }
  },
  {
    path: '/employees/bonus',
    name: 'bonus',
    component: () => import('../views/main/employees/bonus'),
    meta: {
      requiresCEOAuth: true,
      requiresHRAuth: true
    }
  },
  {
    path: '/employees/kpis',
    name: 'kpis',
    component: () => import('../views/main/employees/kpi'),
    meta: {
      requiresCEOAuth: true,
      requiresHRAuth: true,
      requiresHEADAuth: true
    }
  },
  {
    path: '/actions/new',
    name: 'addActionPolicy',
    component: () => import('../views/main/actions/addAction'),
    meta: {
      requiresCEOAuth: true,
      requiresHRAuth: true
    }
  },
  {
    path: '/actionsTaken',
    name: 'actionsTaken',
    component: () => import('../views/main/actions/actionsTaken'),
  },
  {
    path: '/holiday',
    name: 'holiday',
    component: () => import('../views/main/holiday'),
    meta: {
      requiresCEOAuth: true,
      requiresHRAuth: true
    }
  },
  {
    path: '/myProfile',
    name: 'myProfile',
    component : () => import('../views/main/profile')
  },
  {
    path: '/employees/leads',
    name: 'leads',
    component : () => import('../views/main/employees/leads')
  },
  {
    path: '/employees/remove',
    name: 'removeEmployee',
    component : () => import('../views/main/employees/fireEmployee'),
    meta: {
      requiresCEOAuth: true
    }
  },
  {
    path: '/actions/manage',
    name: 'manageActions',
    component : () => import('../views/main/actions/manageActions'),
    meta: {
      requiresCEOAuth: true,
      requiresHRAuth: true,
      requiresAUDITORAuth: true
    }
  },
  {
    path: '/employees/dailyHours',
    name: 'dailyHours',
    component : () => import('../views/main/employees/dailyHours'),
    meta: {
      requiresTLAuth: true
    }
  },
  {
    path: '/employees/workedHours',
    name: 'workedHours',
    component : () => import('../views/main/employees/viewDailyHours'),
    meta: {
      requiresTLAuth: true
    }
  },
  {
    path: '/newActionAgainst',
    name: 'newActionAgainst',
    component : () => import('../views/main/actions/takeAction'),
    meta: {
      requiresCEOAuth: true,
      requiresTLAuth: true,
      requiresHEADAuth: true,
      requiresAUDITORAuth: true
    }
  },
  {
    path: '/employees/adjust',
    name: 'adjustTeams',
    component : () => import('../views/main/employees/adjustTeams'),
    meta: {
      requiresCOLDHEADAuth: true,
      requiresHRAuth: true
    }
  },
  {
    path: '/candidates/add',
    name: 'addCandidate',
    component: () => import('../views/main/candidates/addCandidate'),
    meta: {
      requiresHRAuth: true
    }
  },
  {
    path: '/candidates',
    name: 'viewCandidates',
    component: () => import('../views/main/candidates/viewCandidates'),
    meta: {
      requiresHRAuth: true
    }
  },
  {
    path: '/intervieweesTracker',
    name: 'intervieweesTracker',
    component: () => import('../views/main/interviews/intervieweesTracker'),
    meta: {
      requiresHRAuth: true
    }
  },
  {
    path: '/passedInterviewsTracker',
    name: 'passedInterviewsTracker',
    component: () => import('../views/main/interviews/passedInterviewsTracker'),
    meta: {
      requiresHRAuth: true
    }
  },
  {
    path: '/candidates/:candidate_id',
    name: 'candidateDetails',
    component: () => import('../views/main/candidates/candidateDetails'),
    meta: {
      requiresHRAuth: true
    }
  },
  {
    path: "/candidates/:candidate_id/interviews",
    name: "addInterview",
    component: () => import('../views/main/interviews/addInterview'),
    meta: {
      requiresHRAuth: true
    }
  },
  {
    path: '/candidates/:candidate_id/update',
    name: 'updateCandidate',
    component: () => import('../views/main/candidates/updateCandidate'),
    meta: {
      requiresHRAuth: true
    }
  },
  {
    path: "/candidates/:candidate_id/interviews/:interview_id",
    name: "updateInterview",
    component: () => import('../views/main/interviews/updateInterview'),
    meta: {
      requiresHRAuth: true
    }
  },
  {
    path: "/onboarding-candidates/:type?",
    name: "onboardingTracker",
    component: () => import("../views/main/candidates/onboardingTracker"),
  },
  {
    path: "/candidate-missing-details/:candidate_id/missing_data",
    name: "CandidateOnboardingForm",
    component: () => import("../views/main/candidates/candidateOnboardingForm"),
  },
  {
    path: '/headcountTracker',
    name: 'headcountTracker',
    component: () => import('../views/main/headcountEmployees/employeesTracker'),
    meta: {
      requiresHRAuth: true
    }
  },
  {
    path: '/manageRoles',
    name: 'manageRoles',
    component: () => import('../views/main/headcountEmployees/manageRoles'),
    meta: {
      requiresHRAuth: true
    }
  },
  {
    path: '/addHeadcountEmployee',
    name: 'addHeadcountEmployee',
    component: () => import('../views/main/headcountEmployees/addHeadcountEmployee'),
    meta: {
      requiresHRAuth: true
    }
  },
  {
    path: '/employees/:id',
    name: 'headcountEmployeeDetails',
    component: () => import('../views/main/headcountEmployees/headcountEmployeeDetails'),
    meta: {
      requiresHRAuth: true
    }
  },
  {
    path: '/employees/:id',
    name: 'updateHeadcountEmployee',
    component: () => import('../views/main/headcountEmployees/updateHeadcountEmployee'),
    meta: {
      requiresHRAuth: true
    }
  },
]
}
]
