import { List, ListParams } from "models/list";

const state = {
  roles: new List(),
};

const getters = {
  roles: state => state.roles,
};

const actions = {
  async getRoles({ commit }, { payload, trackLoading = true }) {
    if (trackLoading) { commit("setLoading", true); }
    try {
      const response = await this.$app.$http.get("/employment_roles", { params: new ListParams(payload) });
      if (response.status === 200) {
        commit("setRoles", response.data);
        if (trackLoading) {
          this.$app.$snotify.success("Fetched employment roles successfully.");
        }
      } else {
        throw new Error("Failed to fetch the employment roles");
      }
    } catch(error) {
      this.$app.$snotify.error(
        "An error occurred while fetching the employment roles."
      );
    } finally {
      if (trackLoading) { commit("setLoading", false); }
    }
  },
  async createRole({ commit }, roleData) {
    commit("setLoading", true);
    try{
      const response = await this.$app.$http.post("/employment_roles", roleData);
      if (response.status === 201) {
        this.$app.$snotify.success("Role is created successfully.");
      } else {
        throw new Error("Failed to save the new employment role.");
      }
    } catch (error) {
      this.$app.$snotify.error(
        "An error occurred while saving the new employment roles."
      );
    } finally {
      commit("setLoading", false);
    }

  }
};

const mutations = {
  setRoles(state, rolesData) {
    state.roles = new List({
      items: rolesData.items.map(
        (item) => {
          return {
            id: item.id,
            title: item.title
          };
        }
      ),
      count: rolesData.total_count,
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};