import { List, ListParams} from "models/list";
import { Department } from "models/department";

const state = {
  departments: new List()
};

const getters = {
  departments: state => state.departments
};

const actions = {
  async getDepartments({ commit }, { payload, trackLoading = true}) {
    if (trackLoading) { commit("setLoading", true); }
    try {
      const response = await this.$app.$http.get("/departments", {params: new ListParams(payload)});
      if (response.status === 200) {
        commit("setDepartments", response.data);
        if (trackLoading) {
          this.$app.$snotify.success("Fetched departments successfully.");
        }
      } else {
        throw new Error("Failed to fetch the departments");
      }
    } catch(error) {
      this.$app.$snotify.error(
        "An error occurred while fetching the departments."
      );
    } finally {
      if (trackLoading) { commit("setLoading", false); }
    }
  }
};

const mutations = {
  setDepartments(state, data) {
    state.departments = new List({items: data.items.map((department) => new Department(department)), count: data.total_count});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};