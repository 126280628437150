export default {
  install(Vue) {
    Vue.prototype.$Constants = {
      CANDIDATE_TYPES: [
        "ColdCallingSpecialistCandidate",
        // Add other candidate types as needed
      ],
      candidates: {
        CANDIDATES_TYPE_OPTIONS: [
          {
            title: "Cold Calling Specialist Candidate",
            value: "ColdCallingSpecialistCandidate",
          }, // This has to be the first.
        ],
        VN_EVALUATION_OPTIONS: [
          {
            title: "Pending",
            value: "pending",
          },
          {
            title: "Accepted",
            value: "accepted",
          },
          {
            title: "Rejected",
            value: "rejected",
          },
        ],
        IS_CONTACTED_OPTIONS: [
          {
            title: "Yes",
            value: true,
          },
          {
            title: "No",
            value: false,
          },
        ],
        INTERVIEW_HIRING_DECISION_OPTIONS: [
          {
            title: "Pending",
            value: 0,
          },
          {
            title: "Accepted",
            value: 1,
          },
          {
            title: "Rejected",
            value: 2,
          },
        ],
        MILITARY_STATUS_OPTIONS: [
          "Exempted",
          "Yet to be adhered to",
          "Postponed",
          "Not required",
          "Completed"
        ],
        EDUCATIONAL_STATUS_OPTIONS: [
          "Graduate",
          "Drop out",
          "Under-graduate",
          "Gap year",
        ],
        MARITAL_STATUS_OPTIONS: [
          "Single",
          "Engaged",
          "Married",
        ],
        CANDIDATE_SOURCES: {
          hrDepartment: {
            title: "HR Department",
            value: "hr_department",
          },
          referral: {
            title: "Referral",
            value: "referral",
          },
          externalSource: {
            title: "External Source",
            value: "external_source",
          },
          other: {
            title: "Other",
            value: "other",
          },
        },
        ONBOARDED_OPTIONS: [
          {
            title: "Pending",
            value: "pending",
          },
          {
            title: "Yes",
            value: "yes",
          },
          {
            title: "No",
            value: "no",
          },
        ],
      },
      interviews: {
        // When adding a new interview type option, make sure to include appropriate key-value pairs in the
        // INTERVIEW_TO_CANDIDATE_TYPE and TABLE_HEADERS constants.
        INTERVIEW_TYPES_OPTIONS: [
          {
            title: "Cold Calling Specialist Interviews",
            value: "ColdCallingSpecialistCandidateInterview",
          },
        ],
        // Map the interview model name to its corresponding candidate model name in the backend so the filters
        // in the interviewees page work properly.
        INTERVIEW_TO_CANDIDATE_TYPE: {
          ColdCallingSpecialistCandidateInterview:
            "ColdCallingSpecialistCandidate",
        },
        // Needed by the interviewees page and passed interviews page to display specific interview evaluations for
        // different candidate types.
        TABLE_HEADERS: {
          ColdCallingSpecialistCandidateInterview: [
            {
              text: "English Fluency",
              value: "interview.englishFluency",
              sortable: false,
            },
            {
              text: "English Pronunciation",
              value: "interview.englishPrononcuation",
              sortable: false,
            },
            {
              text: "English Comprehension",
              value: "interview.englishComprehension",
              sortable: false,
            },
            {
              text: "English Vocab",
              value: "interview.englishVocab",
              sortable: false,
            },
            {
              text: "English Grammar",
              value: "interview.englishGrammar",
              sortable: false,
            },
            {
              text: "Typing Speed",
              value: "interview.typingSpeed",
              sortable: false,
            },
            {
              text: "Typing Accuracy",
              value: "interview.typingAccuracy",
              sortable: false,
            },
          ],
        },
      },
      baseAttributes: [
        "interview_date",
        "hiring_decision",
        "ticket_number",
        "onboarded",
        "notes",
      ],
      EMPLOYEE_FIELDS: {
        fullName: {
          type: "text",
          key: "name",
          value: "",
          label: "Full Name",
          rules: [{ name: "required" }],
          classes: "col-12 py-0",
          serverErrors: [],
        },
        nationalId: {
          type: "text",
          key: "national_id",
          value: "",
          label: "National ID",
          rules: [{ name: "required" }],
          classes: "col-md-6 col-sm-12 col-12 py-0",
          serverErrors: [],
        },
        nationalIdExpirationDate: {
          type: "datepicker",
          key: "national_id_expiration_date",
          value: "",
          label: "National ID Expiration Date",
          classes: "col-md-6 col-sm-12 col-12 py-0",
          serverErrors: [],
        },
        department: {
          type: "select",
          key: "department",
          value: "",
          label: "Department",
          options: [], // Populated dynamically
          itemText: "title",
          itemValue: "value",
          classes: "col-md-6 col-sm-12 col-12 py-0",
          rules: [{ name: "required" }],
          serverErrors: [],
        },
        role: {
          type: "select",
          key: "role",
          value: "",
          label: "Role",
          options: [], // Populated dynamically
          itemText: "title",
          itemValue: "value",
          classes: "col-md-6 col-sm-12 col-12 py-0",
          rules: [{ name: "required" }],
          serverErrors: [],
        },
        status: {
          type: "select",
          key: "status",
          value: "",
          label: "Status",
          options: [], // Populated dynamically
          itemText: "title",
          itemValue: "value",
          classes: "col-md-4 col-sm-12 col-12 py-0",
          rules: [{ name: "required" }],
          serverErrors: [],
        },
        contactNumber: {
          type: "text",
          key: "phone_number",
          value: "",
          label: "Contact Number",
          rules: [{ name: "required" }],
          classes: "col-md-4 col-sm-12 col-12 py-0",
          serverErrors: [],
        },
        contactEmail: {
          type: "email",
          key: "communication_email",
          value: "",
          label: "Contact Email",
          classes: "col-md-4 col-sm-12 col-12 py-0",
          serverErrors: [],
        },
        nationality: {
          type: "text",
          key: "nationality",
          value: "",
          label: "Nationality",
          classes: "col-md-4 col-sm-12 col-12 py-0",
          serverErrors: [],
        },
        cityOfResidence: {
          type: "text",
          key: "city_of_residence",
          value: "",
          label: "City of Residence",
          classes: "col-md-4 col-sm-12 col-12 py-0",
          serverErrors: [],
        },
        dateOfBirth: {
          type: "datepicker",
          key: "date_of_birth",
          value: "",
          label: "Date of Birth",
          classes: "col-md-4 col-sm-12 col-12 py-0",
          serverErrors: [],
        },
        militaryStatus: {
          type: "text",
          key: "military_status",
          value: "",
          label: "Military Status",
          classes: "col-md-4 col-sm-12 col-12 py-0",
          serverErrors: [],
        },
        gender: {
          type: "text",
          key: "gender",
          value: "",
          label: "Gender",
          classes: "col-md-4 col-sm-12 col-12 py-0",
          serverErrors: [],
        },
        martialStatus: {
          type: "text",
          key: "martial_status",
          value: "",
          label: "Marital Status",
          classes: "col-md-4 col-sm-12 col-12 py-0",
          serverErrors: [],
        },
        payoneerEmail: {
          type: "email",
          key: "payoneer_email",
          value: "",
          label: "Payoneer Email",
          classes: "col-md-4 col-sm-12 col-12 py-0",
          serverErrors: [],
        },
        emergencyContactName: {
          type: "text",
          key: "emergency_contact_name",
          value: "",
          label: "Emergency Contact Name",
          classes: "col-md-4 col-sm-12 col-12 py-0",
          serverErrors: [],
        },
        emergencyContactNumber: {
          type: "text",
          key: "emergency_contact_number",
          value: "",
          label: "Emergency Contact Number",
          classes: "col-md-4 col-sm-12 col-12 py-0",
          serverErrors: [],
        },
        hiringDate: {
          type: "datepicker",
          key: "hiring_date",
          value: "",
          label: "Hiring Date",
          rules: [{ name: "required" }],
          classes: "col-md-4 col-sm-12 col-12 py-0",
          serverErrors: [],
        },
        directManagerId: {
          type: "text",
          key: "direct_manager_res_id",
          value: "",
          label: "Direct Manager ID",
          classes: "col-md-4 col-sm-12 col-12 py-0",
          serverErrors: [],
        },
        lastWorkingDay: {
          type: "datepicker",
          key: "last_working_day",
          value: "",
          label: "Last Working Day",
          classes: "col-md-4 col-sm-12 col-12 py-0",
          serverErrors: [],
        },
        isFullTime: {
          type: "checkbox",
          key: "is_full_time",
          value: true,
          label: "Is Full-Time",
          rules: [{ name: "required" }],
          classes: "col-md-2 col-sm-4 col-12 py-0",
          serverErrors: [],
        },
        partTimeDateShuffled: {
          type: "text",
          key: "part_time_date_shuffled",
          value: "",
          label: "Part-Time Date Shuffled",
          classes: "col-md-10 col-sm-8 col-12 py-0",
          serverErrors: [],
        },
        personalPhoto: {
          type: "checkbox",
          key: "personal_photo",
          value: false,
          label: "Personal Photo",
          classes: "col-md-2 col-sm-6 col-12 py-0",
          serverErrors: [],
        },
        copyOfNationalId: {
          type: "checkbox",
          key: "copy_of_national_id",
          value: false,
          label: "Copy of National ID",
          classes: "col-md-2 col-sm-6 col-12 py-0",
          serverErrors: [],
        },
        birthCertificate: {
          type: "checkbox",
          key: "birth_certificate",
          value: false,
          label: "Birth Certificate",
          classes: "col-md-2 col-sm-6 col-12 py-0",
          serverErrors: [],
        },
        originalAcademicCertificate: {
          type: "checkbox",
          key: "original_academic_certificate",
          value: false,
          label: "Original Academic Certificate",
          classes: "col-md-2 col-sm-6 col-12 py-0",
          serverErrors: [],
        },
        criminalRecord: {
          type: "checkbox",
          key: "criminal_record",
          value: false,
          label: "Criminal Record",
          classes: "col-md-2 col-sm-6 col-12 py-0",
          serverErrors: [],
        },
        ndaSigned: {
          type: "checkbox",
          key: "nda_signed",
          value: false,
          label: "NDA Signed",
          classes: "col-md-2 col-sm-6 col-12 py-0",
          serverErrors: [],
        },
        medicalInsurance: {
          type: "checkbox",
          key: "medical_insurance",
          value: false,
          label: "Medical Insurance",
          classes: "col-md-2 col-sm-6 col-12 py-0",
          serverErrors: [],
        },
        socialInsurance: {
          type: "checkbox",
          key: "social_insurance",
          value: false,
          label: "Social Insurance",
          classes: "col-md-2 col-sm-6 col-12 py-0",
          serverErrors: [],
        },
        socialInsurancePrint: {
          type: "checkbox",
          key: "social_insurance_print",
          value: false,
          label: "Social Insurance Print",
          classes: "col-md-2 col-sm-6 col-12 py-0",
          serverErrors: [],
        },
        workStub: {
          type: "checkbox",
          key: "work_stub",
          value: false,
          label: "Work Stub",
          classes: "col-md-2 col-sm-6 col-12 py-0",
          serverErrors: [],
        },
        specialCaseForm111: {
          type: "checkbox",
          key: "official_111_form",
          value: false,
          label: "111 Form",
          classes: "col-md-2 col-sm-6 col-12 py-0",
          serverErrors: [],
        },
        disabledWorkQualificationId: {
          type: "checkbox",
          key: "disabled_work_qualification_id",
          value: false,
          label: "Disabled Work Qualification ID",
          classes: "col-md-2 col-sm-6 col-12 py-0",
          serverErrors: [],
        },
        custody: {
          type: "text",
          key: "custody",
          value: "",
          label: "Custody",
          classes: "col-12 py-0",
          serverErrors: [],
        }
      },
      headcount_employees: {
        booleanFields: [
          "is_full_time",
          "social_insurance",
          "medical_insurance",
          "nda_signed",
          "birth_certificate",
          "criminal_record",
          "original_academic_certificate",
          "work_stub",
          "copy_of_national_id",
          "personal_photo",
          "disabled_work_qualification_id",
          "official_111_form",
          "social_insurance_print",
        ],
      },
    };
  },
};
