export class HeadcountEmployee {
  constructor(data) {
    const attributes = data.attributes || {};

    // Basic attributes
    this.RES_ID = attributes.RES_ID || null;
    this.name = attributes.name || "";
    this.communication_email = attributes.communication_email || "";
    this.status = attributes.status || "";
    this.employment_type = attributes.employment_type || "";
    this.role = attributes.role || "";
    this.department = attributes.department || "";

    // Direct manager details (safeguarded against undefined/nested nulls)
    const directManager = attributes.direct_manager || {};
    this.direct_manager_res_id = directManager.res_id || "";
    this.direct_manager_name = directManager.name || "";

    // Computed attributes
    this.formatted_hiring_date = attributes.formatted_hiring_date || "N/A";

    this.national_id = attributes.national_id || "";
    this.nationality = attributes.nationality || "";
    this.city_of_residence = attributes.city_of_residence || "";
    this.gender = attributes.gender || "";
    this.date_of_birth = attributes.date_of_birth || null;
    this.phone_number = attributes.phone_number || "";
    this.emergency_contact_name = attributes.emergency_contact_name || "";
    this.emergency_contact_number = attributes.emergency_contact_number || "";
    this.last_working_day = attributes.last_working_day || null;
    this.payoneer_email = attributes.payoneer_email || "";
    this.is_full_time = attributes.is_full_time ?? null;
    this.part_time_date_shuffled = attributes.part_time_date_shuffled || "";
    this.candidate_id = attributes.candidate_id || "";
    this.hiring_date = attributes.hiring_date || null;

    this.social_insurance = attributes.social_insurance ?? false;
    this.medical_insurance = attributes.medical_insurance ?? false;
    this.nda_signed = attributes.nda_signed ?? false;
    this.military_status = attributes.military_status || "";
    this.martial_status = attributes.martial_status || "";
    this.birth_certificate = attributes.birth_certificate ?? false;
    this.criminal_record = attributes.criminal_record ?? false;
    this.original_academic_certificate = attributes.original_academic_certificate ?? false;
    this.work_stub = attributes.work_stub ?? false;
    this.copy_of_national_id = attributes.copy_of_national_id ?? false;
    this.personal_photo = attributes.personal_photo ?? false;
    this.disabled_work_qualification_id = attributes.disabled_work_qualification_id ?? false;
    this.official_111_form = attributes.official_111_form ?? false;
    this.social_insurance_print = attributes.social_insurance_print ?? false;
    this.national_id_expiration_date = attributes.national_id_expiration_date || null;
    this.custody = attributes.custody || "";
  }
}
