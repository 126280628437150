import { List, ListParams } from "models/list";
import { HeadcountEmployee } from "models/headcountEmployee";

const state = {
  headcountEmployees: new List(),
  employmentStatuses: new List(),
  selectedHeadcountEmployee: null,
  employeesNotification: null,
  employeesImportError: null,
};

const getters = {
  headcountEmployees: (state) => state.headcountEmployees,
  employmentStatuses: (state) => state.employmentStatuses,
  selectedHeadcountEmployee: (state) => state.selectedHeadcountEmployee,
  employeesNotification: (state) => state.employeesNotification,
  employeesImportError: (state) => state.employeesImportError,
};

const actions = {
  async fetchHeadcountEmployees({ commit }, data) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.get("/headcount_employees", {
        params: new ListParams(data),
      });
      if (response.status === 200) {
        commit("setEmployees", response.data);
        this.$app.$snotify.success("Employees fetched successfully.");
      }
    } catch (error) {
      this.$app.$snotify.error("Failed to fetch employees.");
    } finally {
      commit("setLoading", false);
    }
  },
  async getEmploymentStatuses({ commit }, { trackLoading = true }) {
    if (trackLoading) { commit("setLoading", true); }
    try {
      const response = await this.$app.$http.get(
        "/headcount_employees/employment_statuses"
      );
      if (response.status === 200) {
        commit("setEmploymentStatuses", response.data);
        if (trackLoading) {
          this.$app.$snotify.success("Fetched employment statuses successfully.");
        }
      } else {
        throw new Error("Failed to fetch the employment statuses");
      }
    } catch(error) {
      this.$app.$snotify.error(
        "An error occurred while fetching the employment statuses."
      );
    } finally {
      if (trackLoading) { commit("setLoading", false); }
    }
  },
  async createHeadcountEmployee({ commit }, payload ) {
    commit("setLoading", true);
    const requestData = { headcount_employee: payload };
    try {
      const response = await this.$app.$http.post("/headcount_employees", requestData);
      if (response.status === 201) {
        this.$app.$snotify.success("Employee has been added successfully to the headcount.");
        return true;
      } else if (response.status === 200 && response.data.RES_ID) {
        this.$app.$snotify.error(
          `Employee with the same national ID is already in the headcount. ID: ${response.data.RES_ID}`
        );
      } else {
        throw new Error();
      }
    } catch(error) {
      this.$app.$snotify.error(
        "An error occurred while adding the employee to the headcount."
      );
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchStatuses() {
    try {
      const response = await this.$app.$http.get(
        "/headcount_employees/employment_statuses"
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch statuses:", error);
      return [];
    }
  },
  async fetchRoles() {
    try {
      const response = await this.$app.$http.get("/employment_roles");
      return response.data.items.map((role) => ({
        title: role.title,
        value: role.id,
      }));
    } catch (error) {
      console.error("Failed to fetch statuses:", error);
      return [];
    }
  },
  async fetchDepartments() {
    try {
      const response = await this.$app.$http.get("/departments");
      return response.data.items.map((department) => ({
        title: department.attributes.department_name,
        value: department.id,
      }));
    } catch (error) {
      console.error("Failed to fetch statuses:", error);
      return [];
    }
  },
  async fetchHeadcountEmployeeDetails({ commit }, { id }) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.get(`/headcount_employees/${id}`);
      if (response.status === 200) {
        const employeeObj = new HeadcountEmployee(response.data.data);
        commit("setSelectedEmployee", employeeObj);
      }
    } catch (error) {
      console.error("Error fetching employee details:", error);
      throw error;
    } finally {
      commit("setLoading", false);
    }
  },
  async updateHeadcountEmployee({ commit }, { id, updatedFields }) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.put(
        `/headcount_employees/${id}`,
        updatedFields
      );
      if (response.status === 200 || response.status === 201) {
        this.$app.$snotify.success("Employee has been successfully updated.");
        return response.data;
      } else {
        this.$app.$snotify.error("Failed to update employee.");
      }
    } catch (error) {
      console.error("Error updating employee details:", error);
      this.$app.$snotify.error("Failed to update employee.");
      throw error;
    } finally {
      commit("setLoading", false);
    }
  },
  async addOrUpdateEmployee({ commit }, employeeData) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.post(
        "/headcount_employees/add_or_update",
        {
          headcount_employee: employeeData,
        }
      );
      if (response.status === 200 || response.status === 201) {
        this.$app.$snotify.success("Employee has been successfully added.");
        return response.status;
      }else {
        this.$app.$snotify.error("Failed to add employee. Please re-check the input");
      }
    } catch (error) {
      console.error("Error adding employee:", error);
      this.$app.$snotify.error("Failed to add employee.");
    } finally {
      commit("setLoading", false);
    }
  },
  async uploadEmployeesCSV({ commit }, file) {
    commit("setLoading", true);
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await this.$app.$http.post("/headcount_employees/import", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      commit("setEmployeesNotification", {
        type: "success",
        message: `Processed ${response.data.total} records, successfully created ${response.data.successful} employees.`,
      });
      commit("setEmployeesImportError", response.data.errors);
      return response.data;
    } catch (error) {
      commit("setEmployeesNotification", {
        type: "error",
        message: "Failed to upload the CSV file. Please try again.",
      });
      throw error;
    } finally {
      commit("setLoading", false);
    }
  },
};

const mutations = {
  setEmployees: (state, data) => {
    state.headcountEmployees = new List({
      items: data.items.map((employee) => new HeadcountEmployee(employee)),
      count: data.total_count,
    });
  },
  setEmploymentStatuses: (state, data) => {
    state.employmentStatuses = new List({
      items: data.statuses.map((status) => ({
        id: status.value,
        title: status.title
      })),
      count: data.statuses.length
    });
  },
  setSelectedEmployee: (state, employee) => {
    state.selectedHeadcountEmployee = employee;
  },
  setEmployeesNotification: (state, employeesNotification) => {
    state.employeesNotification = employeesNotification;
  },
  setEmployeesImportError: (state, employeesImportError) => {
    state.employeesImportError = employeesImportError;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
