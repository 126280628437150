import { List, ListParams } from "models/list";
import { Candidate } from "models/candidate";
import { InterviewData } from "@/models/interview";
import Vue from "vue";

const state = {
  candidates: new List(),
  selectedCandidate: null,
  notification: null,
  importError: null,
};

const getters = {
  candidates: (state) => state.candidates,
  selectedCandidate: (state) => state.selectedCandidate,
  notification: (state) => state.notification,
  importError: (state) => state.importError,
};

const actions = {
  async getCandidates({ commit }, data) {
    commit("resetCandidates");
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.get("/candidates", {
        params: new ListParams(data),
      });
      if (response.status === 200) {
        commit("setCandidates", response.data);
        this.$app.$snotify.success("Candidates fetched successfully.");
      } else {
        this.$app.$snotify.error(
          this.$app.$t("messages.error.internalServerError")
        );
      }
    } catch (error) {
      this.$app.$snotify.error("An error occurred while fetching candidates.");
    } finally {
      commit("setLoading", false);
    }
  },
  async getInterviewableCandidates({ commit }, data) {
    commit("resetCandidates");
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.get("/candidates/interviewable", {
        params: new ListParams(data),
      });
      if (response.status === 200) {
        commit("setInterviewableCandidates", response.data);
        this.$app.$snotify.success(
          "Interviewable candidates fetched successfully."
        );
      } else {
        this.$app.$snotify.error(
          this.$app.$t("messages.error.internalServerError")
        );
      }
    } catch (error) {
      this.$app.$snotify.error(
        "An error occurred while fetching the data for interviewable candidates."
      );
    } finally {
      commit("setLoading", false);
    }
  },
  async getSelectedCandidate({ commit }, candidate_id) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.get(`/candidates/${candidate_id}`);
      if (response.status === 200) {
        const candidateObj = new Candidate(response.data);
        commit("setSelectedCandidate", candidateObj);
      } else if (response.status === 404) {
        this.$app.$snotify.error(
          this.$app.$t("messages.error.notFound", {
            entity: `Candidate with ID (${candidate_id})`,
          })
        );
      } else {
        this.$app.$snotify.error(
          this.$app.$t("messages.error.internalServerError")
        );
      }
    } catch (error) {
      this.$app.$snotify.error(
        "An error occurred while fetching the data for the selected candidate."
      );
    } finally {
      commit("setLoading", false);
    }
  },
  async createCandidate({ commit }, data) {
    commit("setLoading", true);
    const requestData = { candidate: data };
    try {
      const response = await this.$app.$http.post("/candidates", requestData);
      if (response.status === 200 || response.status === 201) {
        this.$app.$snotify.success("Candidate has been successfully added.");
        return response;
      } else if (response.status === 422) {
        this.$app.$snotify.error(
          "must reference an employee with a department other than HR and an active status."
        );
        return false;
      } else if (response.status === 500) {
        throw new Error();
      }
    } catch (error) {
      this.$app.$snotify.error(
        "An error occurred while creating the candidate."
      );
      this.$app.$snotify.warning(
        "please make sure the data is correct and try again"
      );
      return false;
    } finally {
      commit("setLoading", false);
    }
  },
  async updateCandidate({ commit }, data) {
    commit("setLoading", true);
    const requestData = { candidate: data.candidateData };
    try {
      const response = await this.$app.$http.patch(
        `/candidates/${data.candidate_id}`,
        requestData
      );
      if (response.status === 204) {
        this.$app.$snotify.success("Candidate has been successfully updated.");
        return response;
      } else {
        throw new Error();
      }
    } catch (error) {
      this.$app.$snotify.error(
        "An error occurred while updating the candidate."
      );
      this.$app.$snotify.warning(
        "please make sure the data is correct and try again"
      );
      return false;
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchOnboardingCandidates({ commit }, params) {
    commit("resetCandidates");
    commit("setLoading", true);
    try {
      const type = params.type || "ColdCallingSpecialistCandidate"; // Default type
      const response = await this.$app.$http.get(
        `/onboarding_candidates/${type}`,
        {
          params: new ListParams(params),
        }
      );
      if (response.status === 200) {
        // Commit the fetched data to the store
        commit("setOnboardingCandidates", response.data);
        this.$app.$snotify.success(
          "Onboarding tracking data fetched successfully."
        );
      } else {
        throw new Error("Failed to fetch onboarding tracking data.");
      }
    } catch (error) {
      this.$app.$snotify.error(
        "Failed to fetch candidates tracked for onboarding."
      );
    } finally {
      commit("setLoading", false);
    }
  },
  async fetchCandidateMissingData({ commit }, candidate_id) {
    commit("setLoading", true);
    try {
      const id = candidate_id;
      const response = await this.$app.$http.get(
        `/candidates/${id}/missing_data`
      );
      return response.data;
    } catch (error) {
      this.$app.$snotify.error("Failed to fetch candidate missing fields.");
    } finally {
      commit("setLoading", false);
    }
  },

  async uploadCSV({ commit }, file) {
    commit("setLoading", true);
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await this.$app.$http.post(
        "/candidates/import",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      commit("setNotification", {
        type: "success",
        message: `Processed ${response.data.total} records, successfully created ${response.data.successful} candidates.`,
      });
      commit("setImportError", response.data.errors);
      return response.data;
    } catch (error) {
      commit("setNotification", {
        type: "error",
        message: "Failed to upload the CSV file. Please try again.",
      });
      throw error;
    } finally {
      commit("setLoading", false);
    }
  },
  async onboardCandidate({ commit }, { candidate_id, formData }) {
    commit("setLoading", true);
    try {
      const response = await this.$app.$http.post(
        `/candidates/${candidate_id}/onboard`,
        {
          candidateData: formData,
        }
      );
      if (response.status === 409) {
        // Handle 409 Conflict
        const errorMessage =
        response.errors.error || "Conflict error occurred.";

        this.$app.$snotify.error(errorMessage);
      }
      return response;
    } catch (error) {
      this.$app.$snotify.error("Failed to onboard candidate.");
      throw error;
    } finally {
      commit("setLoading", false);
    }
  },
};

const mutations = {
  resetCandidates: (state) => {
    state.candidates = new List();
  },
  setCandidates: (state, data) => {
    state.candidates = new List({
      items: data.items.map((candidate) => new Candidate(candidate)),
      count: data.total_count,
    });
  },
  setInterviewableCandidates: (state, data) => {
    state.candidates = new List({
      items: data.items.map((entry) => ({
        candidate: new Candidate(entry.candidate),
        interview: entry.interview
          ? new InterviewData(entry.interview, Vue.prototype.$Constants)
          : null,
      })),
      count: data.total_count,
    });
  },
  setSelectedCandidate: (state, candidateData) => {
    state.selectedCandidate = candidateData;
  },
  setNotification: (state, notification) => {
    state.notification = notification;
  },
  setImportError: (state, importError) => {
    state.importError = importError;
  },
  setOnboardingCandidates: (state, data) => {
    state.candidates = new List({
      items: data.data.map((entry) => ({
        candidate: new Candidate(entry.candidate),
        base_interview_id: entry.latest_interview.base_interview_id,
        ticket_number: entry.latest_interview.ticket_number,
        onboarded: entry.latest_interview.onboarded,
      })),
      count: data.extra.total_count,
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
