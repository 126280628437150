export default {
  mainMenu: {
    home: 'Home',
    profile: 'My Profile',
    teams: 'Teams',
    leaves: 'Leaves',
    holidays: 'Holidays',
    target: 'Target',
    employees: 'Employees',
    actions: 'Actions',
    companyLeaves: 'Company Leaves',
    candidates: 'Candidates',
    intervieweesTracker: 'Interviewees Tracker',
    passedInterviewsTracker: 'Passed Interviews Tracker',
    headcount: 'Headcount',
  },
  adminMenu: {
    invitation: 'New Invitation',
    newTeam: 'New Team'
  },
  accountNavMenu: {
    target: "Employees' Target",
    addEmployee: 'Add Employee',
    salaries: 'Salaries',
    addAction: 'Add Action',
    actionsTaken: 'Actions Taken',
    publicProfile: 'Public profile',
    editProfile: 'Update public profile',
    editWorkInfo: 'Update work info',
    editPassword: 'Update password',
    permissions: 'My Permissions',
    supervisors: 'My Supervisors',
    worklogs: 'Work Logs',
    leaves: 'My Leaves',
    assets: 'My Assets',
    goals: 'My Goals',
    kudos: 'My Kudos',
    editKudoRequest: 'Edit Kudos Request',
    documents: 'My Documents',
    requestNewLeave: 'New Leave',
    manageLeaves: 'Manage Leaves',
    newGoal: 'New Goal',
    newDocument: 'New Document',
    editLeaveRequest: 'Edit leave request',
    logout: 'Log out',
    editHelpDeskLinks: 'Edit Links',
    requestNewKudos: 'New Kudos',
    addHoliday: 'Add Holiday to the whole company',
    leads: 'Leads',
    addLead: 'Add Employees Leads',
    removeEmployee: 'Remove Employee',
    fireEmployee: 'Remove an employee from the company',
    manageActions: 'Manage Actions',
    dailyHours: 'Log Daily Hours',
    viewWorkedHours: 'View Worked Hours',
    newActionAgainst: 'Take Action',
    takeAction: 'Take Action against an employee',
    bonus: 'Bonus',
    kpis: 'KPIs',
    adjustTeams: 'Adjust Teams',
    addCandidate: 'Add Candidate',
    viewCandidates: 'View Candidates',
    intervieweesTracker: 'Interviewees',
    passedInterviewsTracker: 'Passed Interviews',
    onboardingTracker: 'Onboarding Tracker',
    headcountTracker: 'Headcount Tracker',
    manageRoles: 'Manage Roles',
    employeesTracker: 'Employees Tracker',
    HeadcountEmployeeDetails: 'Employee Details',
  },
  home: {
    birthdays: 'Birthday | Birthdays',
    anniversaries: 'Work Anniversary | Work Anniversaries',
    leaves: 'Who\'s Off Today?',
    googleEvents: 'Google Events',
    goals: 'My Goals',
    worklogSummary: '{month} Worklog Summary',
    totalWorkingDays: '{days} working days ({hours} hrs)',
    kudos: 'Weekly Kudos',
    headCount: 'Head Count',
    newLeavesRequested: 'New Leaves Requested This Month',
    holidays: 'Official Holidays This Month',
    actions: 'Actions Taken This Month',
  },
  userMenu: {
    profile: 'Profile',
    work: 'Work Info',
    photo: 'Photo',
    password: 'Password',
    permissions: 'Permissions',
    leaves: 'Leaves',
    assets: 'Assets',
    documents: 'Documents',
    teams: 'Teams',
    goals: 'Goals',
    supervisors: 'Supervisors',
    kudos: 'Kudos'
  },
  editProfile: {
    title: 'Public profile',
    subtitle: 'Add information about yourself'
  },
  editWork: {
    title: 'Work Info',
    subtitle: 'Update work information'
  },
  editAvatar: {
    title: 'Avatar',
    subtitle: 'Add a nice photo of yourself for your profile.',
    hint: 'Add / Change Image',
    placeholder: 'No file selected'
  },
  editPassword: {
    title: 'Password',
    subtitle: 'Change your password here.'
  },
  permissions: {
    title: 'Permissions',
    subtitle: 'View all your allowed actions.'
  },
  leaves: {
    title: 'Leaves',
    subtitle: 'View all Leaves.'
  },
  kudos: {
    title: 'Kudos',
    subtitle: 'View all Kudos'
  },
  assets: {
    title: 'Assets',
    subtitle: 'View all Assets.'
  },
  documents: {
    title: 'Documents',
    subtitle: 'View all Documents.'
  },
  supervisors: {
    title: 'Supervisors',
    subtitle: 'View all your supervisors and their permissions.'
  },
  editScope: {
    title: 'Edit User',
    subtitle: 'Edit User Fields'
  },
  goals: {
    title: 'Goals',
    subtitle: 'View all Goals.'
  },
  editUserProfile: {
    title: 'Edit User Data',
    subtitle: 'Complete or Edit User Data'
  },
  editUserPermissions: {
    title: 'Edit User Permissions',
    subtitle: 'Manage User Permissions'
  },
  logHours: {
    submit: 'Submit hours worked for employees',
    view: 'View worked hours for employees',
  },
  syncWithGoogle: 'Sync With Google',
  joinMeeting: 'Join with Google Meet'
};
